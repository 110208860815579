<template>
    <h5 v-if="!hideHeader">{{ $t("Group by Folders") }}</h5>
    <select class="form-select form-select-sm rounded-0" @change="onChange($event)" :placeHolder="$t('Select group by...')">
        <option v-for="(folder, index) in groupByFolder.folders" :value="index">{{ folder.name }}</option>

    </select>
    <div v-if="loading" class="d-flex justify-content-center">
        <div class="spinner-border text-center my-2" role="status">
            <span class="visually-hidden">{{ $t('Loading...') }}</span>
        </div>
    </div>
    <Tree v-if="groupByFolder.current && !loading" :options="groupByFolder.current" :whereClause="whereClause"
        @nodeClick="item => groupByFolder.applyGroupByFilter(item)" @nodeExpand="(node) => groupByFolder.expand(node)"
        ref="treeContainer" class="p-2" :key="groupByFolder.current.id">
        <template #default="{ item }">
            <span :class="{ 'active': item.item.prettyGroupByFilter === groupByFolder.currentPrettyGroupByFilter }">
               {{ item.item.value ?? $t('Blank') }} ({{ item.item.count }})
            </span>
        </template>
    </Tree>
    <div v-if="groupByFolder.currentGroupByFilter" class="mt-1 border-top min-h-unset">
        <small class="d-inline-flex filter">
            {{ groupByFolder.currentPrettyGroupByFilter }}
            <button class="btn btn-sm btn-link" @click="() => { groupByFolder.resetGroupByFilter() }">
                {{ $t('Clear') }}
            </button>
        </small>
    </div>
</template>

<script setup lang="ts">
import GroupByFolder from 'o365.modules.GroupByFolder.ts';
import Tree from 'o365.vue.components.Tree.vue';
import { ref, watch, onBeforeUnmount } from 'vue';

const props = defineProps<{
    dataObject: any;
    whereClause?: string;
    hideHeader?: boolean;
}>();
const loading = ref(true);

const groupByFolder = ref(new GroupByFolder(props.dataObject, "AccessIdPath"));
groupByFolder.value.initialize();
groupByFolder.value.initFolders(0).then(() => {
    loading.value = false;
});

watch(() => (props.dataObject.recordSource.whereClause), (newVal) => {
    groupByFolder.value.reloadWhereClause();
})

const onChange = (event) => {
    loading.value = true;
    groupByFolder.value.selectGroupByByIndex(event.target.value).then(() => {
        loading.value = false;
    });
}

onBeforeUnmount(() => {
    groupByFolder.value.dispose();
});

</script>
<style scoped>
.active {
    background: rgba(var(--bs-primary-rgb), .35);
}

.filter {
    align-items: baseline;
}
</style>